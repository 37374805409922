import React from "react";
import db from "../../hoc/Firebase";
import { withRouter } from "react-router-dom";
//import { isMobile } from "react-device-detect";

const initState = {
  displayAI: "none",
  displayCyber: "none",
  displayCRM: "none",
  displaySEO: "none",
  displayecom: "none",
  displaywebdev: "none",
  firstName: "",
  lastName: "",
  message: "",
  phoneNumber: "",
};

const productdata = [
  {
    id: 1,
    img: "images/taxi-booking.jpg",
    heading: "Taxi Booking App",
    desc: "We are well-versed in mobile application development and can provide applications that allow for the booking of transportation solutions such as taxis for hire. The user interface design of a taxi booking application should be extremely simple to use and user-friendly and should give solutions to everyone."
  },
  {
    id: 2,
    img: "images/real-estate.jpg",
    heading: "Real Estate Property Management System",
    desc: "This refers to the specialized software that allows for the flawless management of real estate properties and assures the convenience of doing business. The nicest part about bespoke software development is that you can gain a competitive edge by delivering goods or services that are ahead of the competition."
  },
  {
    id: 3,
    img: "images/fitness.jpg",
    heading: "Fitness App",
    desc: "It refers to the design and creation, prototyping, development, and distribution of mobile, software, and desktop-based applications that help you keep on top of your fitness objectives and stay physically healthy."
  },
  {
    id: 4,
    img: "images/food-delivery.jpg",
    heading: "Food Delivery App",
    desc: "Online food ordering applications are getting increasingly popular and well-liked. Everyone is overjoyed to get their favorite foods brought to their door in a timely manner. Online meal delivery apps in India are user-friendly software that allows customers to instantly order food from their favorite restaurant or diner and have it delivered to their preferred location."
  }
]
class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initState,
    };
  }

  handleReadmoreAI = () => {
    this.setState({ displayAI: "block" });
  };
  handleReadmoreCyber = () => {
    this.setState({ displayCyber: "block" });
  };
  handleReadmoreCRM = () => {
    this.setState({ displayCRM: "block" });
  };
  handleReadmoreSEO = () => {
    this.setState({ displaySEO: "block" });
  };
  handleReadmoreecom = () => {
    this.setState({ displayecom: "block" });
  };
  handleReadmorewebdev = () => {
    this.setState({ displaywebdev: "block" });
  };

  componentDidMount() {
    var jsElm = document.createElement("script");
    jsElm.src = "js/main.js";
    document.body.appendChild(jsElm);

  }
  handleAddContactUs = () => {
    const metaInfo = {
      platform: navigator.platform,
      connection: navigator.connection
        ? navigator.connection.effectiveType
          ? navigator.connection.effectiveType
          : navigator.connection.type
            ? navigator.connection.type
            : null
        : null,
      maxTouchPoints: navigator.maxTouchPoints,
      userAgent: navigator.userAgent,
    };

    db.collection("contactUs")
      .add({ ...this.state, metaInfo, createdAt: new Date() })
      .then(() => this.state.firstName && this.rollbackStates())
      .catch(() => this.state.firstName && this.rollbackStates());
  };
  rollbackStates = () => {
    this.setState(initState);
  };
  render = () => {
    console.log(this.props);
    return (
      <>
        <section className="home-slider owl-carousel">
          <div
            className="slider-item"
            style={{ backgroundImage: "url(images/firstslide.jpg)" }}
          >
            <div className="overlay" />
            <div className="container">
              <div
                className="row no-gutters slider-text align-items-center justify-content-start"
                data-scrollax-parent="true"
              >
                <div className="col-md-7 ftco-animate">
                  <span className="subheading">
                    Welcome to quickserve it solution
                  </span>
                  <h1 className="mb-4" style={{ fontSize: "3.5vw" }}>The ultimate transformation doesn't happen without the best people.</h1>
                  <p className="slider-service-btn">
                    <a href="/services" className="btn btn-sm btn-primary px-3 py-2 mt-3">
                      Our Services
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="slider-item"
            style={{ backgroundImage: "url(images/2ndSlide1.jpg)" }}
          >
            <div className="overlay" />
            <div className="container">
              <div
                className="row no-gutters slider-text align-items-center justify-content-start"
                data-scrollax-parent="true"
              >
                <div className="col-md-7 ftco-animate">
                  <span className="subheading">
                    Todays Talent, Tommorow Success
                  </span>
                  <h1 className="mb-4" style={{ fontSize: "3.5vw" }} >Your business demands secured, affordable and advanced technology.</h1>
                  <p className="slider-service-btn">
                    <button
                      className="btn btn-sm btn-primary py-2 px-3"
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push("/services");
                      }}
                    >
                      Our Services
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="slider-item"
            style={{ backgroundImage: "url(images/thirdslide.jpg)" }}
          >
            <div className="overlay" />
            <div className="container">
              <div
                className="row no-gutters slider-text align-items-center justify-content-start"
                data-scrollax-parent="true"
              >
                <div className="col-md-7 ftco-animate">
                  <span className="subheading">
                  </span>
                  <h1 className="mb-5" style={{ fontSize: "3.5vw" }}>
                    Re-inventing Technical Solutions From the Ground-Up. Design, re-design, and develop with us.
                  </h1>
                  <p className="slider-service-btn">
                    <button
                      className="btn btn-sm btn-primary py-2 px-3"
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push("/services");
                      }}
                    >
                      Our Services
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="ftco-section">
          <div className="container">
            <div className="row d-flex">
              <div className="col-md-5 order-md-last wrap-about align-items-stretch">
                <div className="wrap-about-border ftco-animate">
                  <div
                    className="img"
                    style={{ backgroundImage: "url(images/about.jpg)" }}
                  />
                  <div className="text">
                    <h3>Our Products are good but our services are even better. We are trusted for our innovative insights. </h3>
                    <p>
                      1. Web Designing.
                      <br /> 2. Web Development. <br /> 3. Android app
                      development. <br /> 4. IOS development. <br /> 5. Hybrid
                      applications. <br /> 6. Consulting.
                    </p>

                    <p>
                      <button
                        className="btn btn-primary py-3 px-4"
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.history.push("/contact");
                        }}
                      >
                        Contact us
                      </button>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-7 wrap-about pr-md-4 ftco-animate" >
                <h2 className="mb-4">Our Organizational Features</h2>
                <div className="row mt-5" id="o-o-f">
                  <div className="col-lg-6">
                    <div className="services active text-center">
                      <div className="icon mt-2 d-flex justify-content-center align-items-center">
                        <span className="flaticon-collaboration" />
                      </div>
                      <div className="text media-body">
                        <h3>Distribute</h3>
                        <p>
                          Identifying the resources and dividing the modules into small chunks. To inculcate a common objective.
                        </p>
                      </div>
                    </div>
                    <div className="services text-center">
                      <div className="icon mt-2 d-flex justify-content-center align-items-center">
                        <span className="flaticon-analysis" />
                      </div>
                      <div className="text media-body">
                        <h3>Deployment</h3>
                        <p>
                          Planning ahead to make the most out of anything is really essential. Hence, We plan, we execute, we deliver.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="services text-center">
                      <div className="icon mt-2 d-flex justify-content-center align-items-center">
                        <span className="flaticon-search-engine" />
                      </div>
                      <div className="text media-body">
                        <h3>Risk Analysis</h3>
                        <p>
                          We always ensure that we take only calculated risks. So, when you implement the ideas, the success rate is mostly high.
                        </p>
                      </div>
                    </div>
                    <div className="services text-center">
                      <div className="icon mt-2 d-flex justify-content-center align-items-center">
                        <span className="flaticon-handshake" />
                      </div>
                      <div className="text media-body">
                        <h3>Capital Market</h3>
                        <p>Investing on stocks requires experience and we know what's best for you.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="ftco-animate w-r-w w-r-w-bg">
          <header>
            <h2 className="w-r-w-heading">Who Are We ?</h2>
          </header>
          <main>
            <p className="ftco-animate" style={{ color: "Black" }} >We are a fast-paced IT product development company with years of experience
              in web and mobile application design & development using the latest frameworks.
              We strive to provide top-notch solutions for all our clients, regardless of
              project size or industry. We are constantly innovating and inventing to bring
              the best possible practices for you all. Our expert team has an extensive
              background in large-scale software development and can handle any size of project.</p>
          </main>
        </section>
        <section className='about-more ftco-animate'>
          <header>
            <h2>A one-stop enterprise for top-notch Software Solutions</h2>
            <p>Check out our core values</p>
          </header>
          <div className='a-m-flex'>
            <div className='a-m-flexitem ftco-animate'>
              <img alt='Trust and dependability in working with QuickServe' src="/images/trust1.png" />
              <h2>Trust and dependability</h2>
              <p>We dedicate our services to meeting the demands of our clients.
                We ensure that the customers can put their faith in us by being
                straightforward and honest in our operations. We are looking forward
                to developing long-term relationships with the majority of our clientele.</p>
            </div>
            <div className='a-m-flexitem ftco-animate'>
              <img alt='Teamwork and dedicated people at quickserve it solution' src="/images/teamwork.png" />
              <h2>Teamwork</h2>
              <p>When we work together, we have the ability to unleash enormous potential.
                We believe in collaboration between our employees and clients, which results in a smoother workflow.</p>
            </div>
            <div className='a-m-flexitem ftco-animate'>
              <img alt='Performance and smart work' src="/images/growth.png" />
              <h2>Affective Performance</h2>
              <p>We are well aware of how competitive online businesses can be. As a result,
                we include cutting-edge high-power technology in the design and development
                of our products. Our clients get our undivided attention until they are
                completely happy with the quality of our products.</p>
            </div>
          </div>
        </section>
        <section className="w-r-w">
          <div className="w-i-o-s">
            <div className="w-i-o-s-text ftco-animate">
              <h2>What Is Our Story?</h2>
              <p>We serve as a link between businesses and potential clients. Our technological
                solutions attract bright minds who want to make a difference in the world.
                Every software product is more than simply an IT solution; it represents
                the company's future growth. We value your ideas and interests, hence
                we prefer personalized online solutions.</p>
              <p>We value your ideas and interests, hence we prefer personalized online solutions.
                We are respected for our creative thoughts.  We carve out the best IT solutions
                for your needs based on your creativity, goals, and concepts. The use of
                cutting-edge technology enables our clients to compete and succeed online.</p>
            </div>
            <div className="w-i-o-s-img">
              <img src="images/whorwe.jpg" alt="Who are we" />
            </div>
          </div>
        </section>
        {/* <section
          className="ftco-section ftco-counter"
          style={{ paddingBottom: "0px" }}
        >
          <div className="container">
            <div className="row justify-content-center mb-5 pb-2 d-flex">
              <div className="col-md-6 align-items-stretch d-flex">
                <div
                  className="img img-video d-flex align-items-center"
                  style={{
                    backgroundImage:
                      "url(https://www.consilium.europa.eu/media/43472/eu_response_covid-19_thumb.jpg)",
                  }}
                ></div>
              </div>
              <div className="col-md-6 heading-section ftco-animate pl-lg-5 pt-md-0 pt-5">
                <h2 className="mb-4">CSR Activities</h2>
                <p>
                  As a corporate, young minds we understand our responsibilities
                  very well. Hence in this pandemic we came up high end solution
                  and named it Covid-Mitra.
                </p>
                <h4>Functionalities</h4>
                <ul>
                  <li>Dropping the food packets to the needy.</li>
                  <li>
                    Tracking the people in two categories (inside home/outside
                    home)
                  </li>
                  <li>Collecting migration workers database.</li>
                </ul>
                <p>
                  This way we have helped our locality and around the city for
                  better monitoring.
                </p>
                <p>
                  We as a team thanks to the people for giving us a social
                  recognition for the same.
                </p>
                <p>Thank for reading.</p>
              </div>
            </div>
          </div>
        </section> */}
        {/* <section
          className="ftco-intro ftco-no-pb img"
          style={{ backgroundImage: "url(images/bg_3.jpg)" }}
        >
          <div className="container">
            <div className="row justify-content-center mb-5">
              <div className="col-md-10 text-center heading-section heading-section-white ftco-animate">
                <h2 className="mb-0">You Always Get the Best Guidance</h2>
              </div>
            </div>
          </div>
        </section> */}
        {/* <section className="ftco-counter" id="section-counter">
          <div className="container">
            <div className="row d-md-flex align-items-center justify-content-center">
              <div className="wrapper">
                <div className="row d-md-flex align-items-center">
                  <div className="col-md d-flex justify-content-center counter-wrap ftco-animate">
                    <div className="block-18">
                      <div className="icon">
                        <span className="flaticon-doctor" />
                      </div>
                      <div className="text">
                        <strong className="number" data-number={10}>
                          0
                        </strong>
                        <span>Projects Completed</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md d-flex justify-content-center counter-wrap ftco-animate">
                    <div className="block-18">
                      <div className="icon">
                        <span className="flaticon-doctor" />
                      </div>
                      <div className="text">
                        <strong className="number" data-number={10}>
                          0
                        </strong>
                        <span>Satisfied Customer</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md d-flex justify-content-center counter-wrap ftco-animate">
                    <div className="block-18">
                      <div className="icon">
                        <span className="flaticon-doctor" />
                      </div>
                      <div className="text">
                        <strong className="number" data-number={2}>
                          0
                        </strong>
                        <span>Awwards Received</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md d-flex justify-content-center counter-wrap ftco-animate">
                    <div className="block-18">
                      <div className="icon">
                        <span className="flaticon-doctor" />
                      </div>
                      <div className="text">
                        <strong className="number" data-number={5}>
                          0
                        </strong>
                        <span>Years of Experienced</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* <section className="ftco-section" style={{ paddingBottom: "4rem" }}> */}
        <section className="ftco-section waves-reverse" style={{ paddingTop: 10 }}>
          <div className="container">
            <div className="row justify-content-center mb-5 pb-2">
              <div className="col-md-8 text-center heading-section ftco-animate">
                <div className="our-best-services">
                  <h2 className="b-s-h">Our Best Services</h2>
                  <span></span>
                </div>
                <p className="b-s-p">At our company, we are committed to customizing our offerings so that you can get your desired solutions
                  at competitive prices. Our dedicated sales team will work with you to find the best possible deal for
                  your budget. We offer different types of development services that are scalable, secured, and cost-effective.
                  Trust us with your next project and see how we transform it!</p>
              </div>
            </div>
            <div className="row no-gutters">
              <div className="col-lg-4 d-flex">
                <div className="services-2 noborder-left text-center ftco-animate">
                  <div className="icon mt-2 d-flex justify-content-center align-items-center">
                    <span className="flaticon-analysis" />
                  </div>
                  <div className="text media-body">
                    <h3>Customer Relationship Management</h3>
                    <p>
                      Create new customers and retain the current ones with this CRM software. This tool aids in
                      customer management, outreach, and overall organization.
                    </p>
                    <p style={{ display: this.state.displayCRM }}>
                      The best way to keep your monthly
                      expenses low is by keeping your customer base growing. Get this CRM today to keep a happy business!
                    </p>
                    <button
                      className="btn btn-primary buttonHover"
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleReadmoreCRM();
                      }}
                    >
                      Read More
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 d-flex">
                <div className="services-2 text-center ftco-animate">
                  <div className="icon mt-2 d-flex justify-content-center align-items-center">
                    <span className="flaticon-business" />
                  </div>
                  <div className="text media-body">
                    <h3>Application Development</h3>
                    <p>
                      Delivering revolutionary and user-centric mobile applications making businesses happen anywhere
                      and anytime. Let’s empower your business with a wide range of customized and scalable mobile apps.
                    </p>
                    <button
                      className="btn btn-primary buttonHover"
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleReadmoreAI();
                      }}
                    >
                      Read More
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 d-flex">
                <div className="services-2 text-center ftco-animate">
                  <div className="icon mt-2 d-flex justify-content-center align-items-center">
                    <span className="flaticon-insurance" />
                  </div>
                  <div className="text media-body">
                    <h3>Web Development</h3>
                    <p>
                      Engage in the active digital transition by harnessing responsive, cost-effective, and impactful
                      web designing and developing services from us. We integrate the latest technologies to
                    </p>
                    <p style={{ display: this.state.displaywebdev }}>
                      deliver the perfectly formulated solution to your business needs.
                    </p>
                    <button
                      className="btn btn-primary buttonHover"
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleReadmorewebdev();
                      }}
                    >
                      Read More
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 d-flex">
                <div className="services-2 noborder-left text-center ftco-animate">
                  <div className="icon mt-2 d-flex justify-content-center align-items-center">
                    <span className="flaticon-money" />
                  </div>
                  <div className="text media-body">
                    <h3>Enterprise Resource And Planning</h3>
                    <p>
                      Custom or bespoke software design is one of our specialties. Start the process of attaining a successful,
                      stable business by getting customized software just for your business.
                    </p>
                    <button
                      className="btn btn-primary buttonHover"
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleReadmoreAI();
                      }}
                    >
                      Read More
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 d-flex">
                <div className="services-2 text-center  ftco-animate">
                  <div
                    className="icon mt-2 d-flex justify-content-center align-items-center"
                    style={{ padding: "inherit" }}
                  >
                    <img
                      src="images/AI.png"
                      alt="img"
                      style={{ width: 50, height: 50 }}
                    />
                  </div>
                  <div className="text media-body">
                    <h3>Artificial Intelligence (AI)</h3>
                    <p>
                      Avoid the problems of tedious labor and low work productivity with our AI-based smart solutions.
                      Incorporate artificial intelligence to save your energy and time through
                    </p>
                    <p style={{ display: this.state.displayAI }}>
                      automated business solutions. Use your time and energy for more focused work.
                    </p>
                    <button
                      className="btn btn-primary buttonHover"
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleReadmoreAI();
                      }}
                    >
                      Read More
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 d-flex">
                <div className="services-2 text-center ftco-animate">
                  <div
                    className="icon mt-2 d-flex justify-content-center align-items-center"
                    style={{ padding: "inherit" }}
                  >
                    <img
                      src="images/cyberLogo.png"
                      alt="img"
                      style={{ width: 50, height: 50 }}
                    />
                  </div>
                  <div className="text media-body">
                    <h3>Cyber Security</h3>
                    <p className="wow fadeInRight" data-wow-duration="0.5s">
                      Cyber Security can be overwhelming. It's impossible to deal with it unless you have experts along with you.
                      Strong data encryption and cryptography 
                    </p>
                    <p style={{ display: this.state.displayCyber }}>mechanism is the perfect solution for tackling this problem.
                    </p>
                    <button
                      className="btn btn-primary buttonHover1 "
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleReadmoreCyber();
                      }}
                    >
                      Read More
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 d-flex">
                <div className="services-2 text-center noborder-bottom noborder-left ftco-animate">
                  <div
                    className="icon mt-2 d-flex justify-content-center align-items-center"
                    style={{ padding: "inherit" }}
                  >
                    <img
                      src="images/online-shop.png"
                      alt="img"
                      style={{ width: 50, height: 50 }}
                    />
                  </div>
                  <div className="text media-body">
                    <h3>Ecommerce Development</h3>
                    <p className="wow fadeInRight" data-wow-duration="0.5s">
                      It’s time to surpass the ordeals of closing hours and geographical barriers in your businesses.
                      Hold our hands and step into the sector of digitalization.
                    </p>
                    <p style={{ display: this.state.displayecom }}>
                      Transform your in-store business into an e-commerce success story
                    </p>
                    <button
                      className="btn btn-primary buttonHover1 "
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleReadmoreecom();
                      }}
                    >
                      Read More
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 d-flex">
                <div className="services-2 text-center noborder-bottom ftco-animate">
                  <div
                    className="icon mt-2 d-flex justify-content-center align-items-center"
                    style={{ padding: "inherit" }}
                  >
                    <img
                      src="images/seo.png"
                      alt="img"
                      style={{ width: 50, height: 50 }}
                    />
                  </div>
                  <div className="text media-body">
                    <h3>Search Engine Optimization(SEO)</h3>
                    <p className="wow fadeInRight" data-wow-duration="0.5s">
                      Only writing content for your website isn’t enough. You need optimized content with keywords,
                      backlinks, and URL 
                    </p>
                    <p style={{ display: this.state.displaySEO }}>
                      slugs to rank in the google searches. Give it a try and see the boom in your
                      sales scores. We have an excellent team of search engine optimizers and webmasters who are dedicated
                      to SEO projects and Internet marketing.
                    </p>
                    <button
                      className="btn btn-primary buttonHover1 "
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleReadmoreSEO();
                      }}
                    >
                      Read More
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 d-flex">
                <div className="services-2 text-center noborder-bottom ftco-animate">
                  <div
                    className="icon mt-2 d-flex justify-content-center align-items-center"
                    style={{ padding: "inherit" }}
                  >
                    <img
                      src="images/graphic-design.png"
                      alt="img"
                      style={{ width: 50, height: 50 }}
                    />
                  </div>
                  <div className="text media-body">
                    <h3>Graphic Designing</h3>
                    <p className="wow fadeInRight" data-wow-duration="0.5s">
                      Improvise your customer experience by leveraging innovative and attractive designs in your digital modules.
                      We are here to transform your business into a brand and 
                    </p>
                    <p style={{ display: this.state.displayCyber }}>your idea into a reality.
                      It is time to boost your brand with us.
                    </p>
                    <button
                      className="btn btn-primary buttonHover1 "
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleReadmoreCyber();
                      }}
                    >
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="working-strategy">
          <header>
            <h2>Our Working Strategy</h2>
          </header>
          <main className='strategy-body'>
            <div className='s-b-head'>
              <div>
                <img src="/images/understanding.png" alt="understanding customers and relations" />
                <h2>Understanding Customers</h2>
              </div>
              <div>
                <img src="/images/bulb.png" alt='ideas and great innovation at quickserve' />
                <h2>Innovations</h2>
              </div>
              <div>
                <img src="/images/web-design.png" alt="UI and Desging at quickserve" />
                <h2>Design</h2>
              </div>
              <div>
                <img src="/images/engineering.png" alt="building super-intelligent software solutions using BD technologies, Machine Learning and AI." />
                <h2>Engineering</h2>
              </div>
            </div>
            <div className='s-b-def'>
              <main className="home-slider owl-carousel">
                <div id="customers" className='s-b-desc' style={{ opacity: "1" }} data-active-carousel="customers" >
                  <article>
                    <h2>Understanding Customers</h2>
                    <p>Forget about emails and PowerPoint presentations! We believe in direct
                      one-on-one communication. We prefer to meet with our clients on a regular
                      basis in order to better understand their companies and needs.
                      We closely adhere to their organizational needs and make their inventive
                      thoughts a reality. Based on our conversations, we strategize and design sprints.</p>
                  </article>
                </div>
                <div id="innovation" className='s-b-desc' data-active-carousel="innovation">
                  <article>
                    <h2>Innovations</h2>
                    <p>Once the product concepts have been finalized, we use new techniques
                      to make them distinctive and exceptional. Our skilled development team
                      contributes significant ideas to make our products better and more
                      excellent.</p>
                  </article>
                </div>
                <div id="design" className='s-b-desc' data-active-carousel="design">
                  <article>
                    <h2>Design</h2>
                    <p>We provide an exceptional user experience by incorporating the most recent
                      UI/UX trends. With our designs, your online company strategy seems
                      sophisticated, personalized, and advanced. We strive to keep the designs
                      simple yet attractive, vibrant yet user-friendly.</p>
                  </article>
                </div>
                <div id="engineering" className='s-b-desc' data-active-carousel="engineering">
                  <article>
                    <h2>Engineering</h2>
                    <p>With a clear goal of solving complex issues for customers, we specialize in high-end
                      mobile/web apps, outsourced product development, custom software research & development across
                      most relevant technologies such as ReactJs, NodeJs, AngularJs, React - Native,
                      Ionic, Flutter, Android, PHP, CodeIgniter, Laravel, C#.Net, Asp.Net, WordPress, Shopify, MySQL,
                      Python, Django, Postgresql, MongoDB, Firebase and also in building super-intelligent software
                      solutions using BD technologies, Machine Learning and AI.</p>
                  </article>
                </div>
              </main>
            </div>
          </main>
        </section>
        <section id="our-products">
          <header className="o-p-head mb-4">
            <h1 >Our Products</h1>
            <span></span>
          </header>
          <main className='our-product-body'>
            {
              productdata.map((product) => {
                return (
                  <div className="project" key={product.id}>
                    <div className="p-browser">
                      <div className="p-circle"></div>
                      <div className="p-circle"></div>
                      <div className="p-circle"></div>
                    </div>
                    <h3 className='p-heading'>{product.heading}</h3>
                    <img src={product.img} alt="" className="p-img" />
                    <p className='p-desc'>{product.desc}</p>
                  </div>
                );
              })
            }

          </main>
        </section>
        {/* <section
          className="ftco-intro ftco-no-pb img"
          style={{ backgroundImage: "url(images/bg_1.jpg)" }}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-9 col-md-8 d-flex align-items-center heading-section heading-section-white ftco-animate">
                <h2 className="mb-3 mb-md-0">
                  You Always Get the Best Guidance
                </h2>
              </div>
              <div className="col-lg-3 col-md-4 ftco-animate">
                <p className="mb-0">
                  <a href="/home" className="btn btn-white py-3 px-4">
                    Request Quote
                  </a>
                </p>
              </div>
            </div>
          </div>
        </section> */}
        {/* <section
          className="ftco-section ftco-no-pb"
          style={{ paddingTop: "4rem" }}
        >
          <div className="container-fluid px-0">
            <div className="row no-gutters justify-content-center mb-5">
              <div className="col-md-7 text-center heading-section ftco-animate">
                <h2 className="mb-4">Our Recent Projects</h2>
              </div>
            </div>
            <div
              className="row ftco-animate justify-content-center"
              style={{ width: "inherit" }}
            >
              <div className="col-md-12" style={{ marginLeft: 30 }}>
                <div className="carousel-testimony owl-carousel">
                  <div
                    className="item"
                    style={isMobile ? { width: 350, margin: 0 } : { margin: 0 }}
                  >
                    <div
                      className="project img ftco-animate d-flex justify-content-center align-items-center"
                      style={{ backgroundImage: "url(images/books.png)" }}
                    >
                      <div
                        className={isMobile ? "overlayProject" : "overlay"}
                      />
                      {/* <div
                        className="d-flex align-items-center justify-content-center"
                        style={{
                          top: 7,
                          left: 23,
                          position: "absolute",
                          paddingBottom: 10,
                        }}
                      >
                        <h3
                          style={{
                            fontSize: 20,
                            textAlign: "initial",
                            color: "white",
                          }}
                        >
                          Book Distribution System
                        </h3>
                      </div>
                      <a
                        href="/"
                        className={
                          isMobile
                            ? "btn-siteproject d-flex align-items-center justify-content-center"
                            : "btn-site d-flex align-items-center justify-content-center"
                        }
                      >
                        <span className="icon-subdirectory_arrow_right" />
                      </a>
                      <div className="text text-center p-4">
                        <h3
                          style={{
                            fontSize: 20,
                            textAlign: "initial",
                            color: "white",
                          }}
                        >
                          Book Distribution System
                        </h3>
                        <span style={{ fontSize: 12, textAlign: "initial" }}>
                          <p className="description">
                            <span>{`What does it do?`}</span>
                            <br />
                            <span>{`- Manages stock.`}</span>
                            <br />
                            <span>{`- Makes books ready to go, on prior request.`}</span>
                            <br />
                            <span>{`-Expense Detailing.`}</span>
                            <br />
                            <span>{`-Managing database of the partners around the market.`}</span>
                            &nbsp;
                          </p>
                        </span>
                      </div>
                      <span id="projectHeading"> Book Distribution System</span>
                    </div>
                  </div>
                  <div
                    className="item"
                    style={isMobile ? { width: 350, margin: 0 } : { margin: 0 }}
                  >
                    <div
                      className="project  img ftco-animate d-flex justify-content-center align-items-center"
                      style={{ backgroundImage: "url(images/tracking.png)" }}
                    >
                      <div
                        className={isMobile ? "overlayProject" : "overlay"}
                      />

                      <a
                        href="/"
                        className={
                          isMobile
                            ? "btn-siteproject d-flex align-items-center justify-content-center"
                            : "btn-site d-flex align-items-center justify-content-center"
                        }
                      >
                        <span className="icon-subdirectory_arrow_right" />
                      </a>
                      <div className="text text-center p-4">
                        <h3
                          style={{
                            fontSize: 20,
                            textAlign: "initial",
                            color: "white",
                          }}
                        >
                          Tracking application
                        </h3>
                        <span style={{ fontSize: 12, textAlign: "initial" }}>
                          <p className="description">
                            {`We have made a tracking application to track the associates end to end. Associates have to just start the application and application will make route of the same.So that the information provided by the associates can be cross checked and the owner can evaluate money for accountability.`}
                          </p>
                        </span>
                      </div>
                      <span id="projectHeading"> Tracking application</span>
                    </div>
                  </div>
                  <div
                    className="item"
                    style={isMobile ? { width: 350, margin: 0 } : { margin: 0 }}
                  >
                    <div
                      className="project  img ftco-animate d-flex justify-content-center align-items-center"
                      style={{ backgroundImage: "url(images/Tractor.png)" }}
                    >
                      <div
                        className={isMobile ? "overlayProject" : "overlay"}
                      />
                      <a
                        href="/"
                        className={
                          isMobile
                            ? "btn-siteproject d-flex align-items-center justify-content-center"
                            : "btn-site d-flex align-items-center justify-content-center"
                        }
                      >
                        <span className="icon-subdirectory_arrow_right" />
                      </a>
                      <div className="text text-center p-4">
                        <h3
                          style={{
                            fontSize: 20,
                            textAlign: "initial",
                            color: "white",
                          }}
                        >
                          Tractor App
                        </h3>
                        <span style={{ fontSize: 12, textAlign: "initial" }}>
                          <p className="description">
                            {`We have provided the basis of categorised clients with multiple stores and user(admin/ operator). Inventory management has been done with no hustle at all. And we are also showing reports at various level so that admin can plan his/her day ahead.`}
                          </p>
                        </span>
                      </div>{" "}
                      <span id="projectHeading"> Tracking application</span>
                    </div>
                  </div>
                  <div
                    className="item"
                    style={isMobile ? { width: 350, margin: 0 } : { margin: 0 }}
                  >
                    <div
                      className="project  img ftco-animate d-flex justify-content-center align-items-center"
                      style={{ backgroundImage: "url(images/loan.webp)" }}
                    >
                      <div
                        className={isMobile ? "overlayProject" : "overlay"}
                      />
                      <a
                        href="/"
                        className={
                          isMobile
                            ? "btn-siteproject d-flex align-items-center justify-content-center"
                            : "btn-site d-flex align-items-center justify-content-center"
                        }
                      >
                        <span className="icon-subdirectory_arrow_right" />
                      </a>
                      <div className="text text-center p-4">
                        <h3
                          className="description"
                          style={{
                            fontSize: 20,
                            textAlign: "initial",
                            color: "white",
                          }}
                        >
                          Loan by Jewellery Shop
                        </h3>
                        <span style={{ fontSize: 12, textAlign: "initial" }}>
                          <p className="description">
                            We have provided to client on taking the details and
                            performing calculations <br />
                            (Simple and Compound Interests) implicable to their
                            clients, with ease and make the things work
                            paperless.
                          </p>
                        </span>
                      </div>
                      <span id="projectHeading"> Loan by Jewellery Shop</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <section id="skeptical" className="text-center">
          <header>
            <h2>Are You Being Skeptical ?</h2>
          </header>
          <main>
            <p style={{ color: "Black" }}>It is acceptable to be skeptical. We recognize that it is your "business."
              However, we can promise you that your company is in good hands. For many years,
              we have dealt directly with our clients, knowing their needs from their viewpoint
              and their target audience. We work on various modules, test them, show them to our
              clients, and continue to work on them until they are pleased. As a result, we can
              guarantee you that you need not be concerned. Because, as much as it is your
              business, it is also our business, and what is most important to us is your
              satisfaction.
            </p>
          </main>
        </section>
        <section
          className="ftco-section bg-light"
        // style={{ paddingBottom: "0px" }}
        >
          <div className="container">
            <div className="row justify-content-center mb-5 pb-2">
              <div className="col-md-8 text-center heading-section ftco-animate">
                <h2 className="mb-4">
                  <span>Our</span> Testimonials
                </h2>
                <p>
                  We thanks our clients for counting on our excellence.
                  Appreciation stating confidence on ours efforts by your
                  clients is a thing to cherish. It gives us immense pleasure
                  and a possitive booster whenever our esteemed clients take
                  time to review us towards our deiliverables through
                  testimonials.
                </p>
              </div>
            </div>
            <section
              className="home-slider owl-carousel"
              style={{ height: "350px" }}
            >
              <div className="slider-item ftco-animate">
                <div className="blog-entry">
                  <div className="text bg-white p-4">
                    <h3 className="heading">
                      <a href="/home">
                        Right destination to fulfill all web requirements under
                        one roof is the only QSITS
                      </a>
                    </h3>
                    <p>
                      Thank you to the team for delivering all my given works in
                      stipulated time. Good job done
                    </p>
                    <div className="d-flex align-items-center mt-4">
                      <p className="ml-auto mb-0">
                        <a href="/home" className="mr-2">
                          Salchet Wilson. Canada
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="slider-item ftco-animate">
                <div className="blog-entry">
                  <div className="text bg-white p-4">
                    <h3 className="heading">
                      QuickServe IT Solution is absolutely a fantastic team of
                      professional developers and designers
                    </h3>
                    <p>
                      We recently came across to get our website designed and we
                      are absolutely happy with the way it turned out. The team
                      is a mix of experts in all functionalities.
                    </p>
                    <div className="d-flex align-items-center mt-4">
                      <p className="ml-auto mb-0">
                        <a href="/home" className="mr-2">
                          Manish Wadhwa. Dubai. UAE
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="slider-item ftco-animate">
                <div className="blog-entry">
                  <div className="text bg-white p-4">
                    <h3 className="heading">
                      Second time I have dealt with them
                    </h3>
                    <p>Fast efficient service with swift communication.</p>
                    <div className="d-flex align-items-center mt-4">
                      <p className="ml-auto mb-0">
                        <a href="/home" className="mr-2">
                          Daniel Crow. Australia
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="slider-item ftco-animate">
                <div className="blog-entry">
                  <div className="text bg-white p-4">
                    <h3 className="heading">
                      I was completely exhausted with the fake commitments
                      companies giving to me.
                    </h3>
                    <p>
                      I came across the team of Quick Serve IT solutions last
                      year and I must admit they are best in their efforts. I
                      would certainly recommend others to take a moment
                      discussing with them. Happy with their quality of work
                      with on time delivery.
                    </p>
                    <div className="d-flex align-items-center mt-4">
                      <p className="ml-auto mb-0">
                        <a href="/home" className="mr-2">
                          Sanjeev Jain, India
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="slider-item ftco-animate">
                <div className="blog-entry">
                  <div className="text bg-white p-4">
                    <h3 className="heading">
                      Very professional and easy to handle and a super skilled
                      team.
                    </h3>
                    <p>
                      Choosing you all was certainly a good decision of mine.
                    </p>
                    <div className="d-flex align-items-center mt-4">
                      <p className="ml-auto mb-0">
                        <a href="/#" className="mr-2">
                          Sandeep Maheswari, India
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="slider-item ftco-animate">
                <div className="blog-entry">
                  <div className="text bg-white p-4">
                    <h3 className="heading">
                      Very much satisfied with their qualitative services.
                    </h3>
                    <p>
                      I have been in their contact with three of my most
                      important projects. They are really outstanding in their
                      deliverables.
                    </p>
                    <div className="d-flex align-items-center mt-4">
                      <p className="ml-auto mb-0">
                        <a href="/home" className="mr-2">
                          Mominh Alam, India
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>

        {/* <section
          className="ftco-section ftco-consult ftco-no-pt ftco-no-pb"
          style={{ backgroundImage: "url(images/bg_5.jpg)" }}
          data-stellar-background-ratio="0.5"
        >
          <div className="overlay" />
          <div className="container">
            <div className="row justify-content-end">
              <div className="col-md-6 py-5 px-md-5">
                <div className="py-md-5">
                  <div className="heading-section heading-section-white ftco-animate mb-5">
                    <h2 className="mb-4">Request A Quote</h2>
                    <p>
                      Far far away, behind the word mountains, far from the
                      countries Vokalia and Consonantia, there live the blind
                      texts.
                    </p>
                  </div>
                  <form action="#" className="appointment-form ftco-animate">
                    <div className="d-md-flex">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="First Name"
                          value={this.state.firstName}
                          onChange={(e) => {
                            e.preventDefault();
                            this.setState({ firstName: e.target.value });
                          }}
                        />
                      </div>
                      <div className="form-group ml-md-4">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Last Name"
                          value={this.state.lastName}
                          onChange={(e) => {
                            e.preventDefault();
                            this.setState({ lastName: e.target.value });
                          }}
                        />
                      </div>
                    </div>
                    <div className="d-md-flex">
                      <div className="form-group ml-md-4">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Phone"
                          value={this.state.phoneNumber}
                          onChange={(e) => {
                            e.preventDefault();
                            this.setState({ phoneNumber: e.target.value });
                          }}
                        />
                      </div>
                    </div>
                    <div className="d-md-flex">
                      <div className="form-group">
                        <textarea
                          name
                          id
                          cols={30}
                          rows={2}
                          onChange={(e) => {
                            e.preventDefault();
                            this.setState({ message: e.target.value });
                          }}
                          value={this.state.message}
                          className="form-control"
                          placeholder="Message"
                          defaultValue={""}
                        />
                      </div>
                      <div className="form-group ml-md-4">
                        <input
                          type="submit"
                          onClick={(e) => {
                            e.preventDefault();
                            this.handleAddContactUs();
                          }}
                          defaultValue="Request A Quote"
                          className="btn btn-white py-3 px-4"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* <section className="ftco-section bg-light">
        <div className="container">
          <div className="row justify-content-center mb-5 pb-2">
            <div className="col-md-8 text-center heading-section ftco-animate">
              <h2 className="mb-4">
                <span>Recent</span> Blog
              </h2>
              <p>
                Separated they live in. A small river named Duden flows by their
                place and supplies it with the necessary regelialia. It is a
                paradisematic country
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-4 ftco-animate">
              <div className="blog-entry">
                <a
                  href="blog-single.html"
                  className="block-20 d-flex align-items-end"
                  style={{ backgroundImage: 'url("images/image_1.jpg")' }}
                >
                  <div className="meta-date text-center p-2">
                    <span className="day">26</span>
                    <span className="mos">June</span>
                    <span className="yr">2019</span>
                  </div>
                </a>
                <div className="text bg-white p-4">
                  <h3 className="heading">
                    <a href="/home">
                      Finance And Legal Working Streams Occur Throughout
                    </a>
                  </h3>
                  <p>
                    Far far away, behind the word mountains, far from the
                    countries Vokalia and Consonantia, there live the blind
                    texts.
                  </p>
                  <div className="d-flex align-items-center mt-4">
                    <p className="mb-0">
                      <a href="/home" className="btn btn-primary">
                        Read More
                        <span className="ion-ios-arrow-round-forward" />
                      </a>
                    </p>
                    <p className="ml-auto mb-0">
                      <a href="/home" className="mr-2">
                        Admin
                      </a>
                      <a href="/home" className="meta-chat">
                        <span className="icon-chat" /> 3
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 ftco-animate">
              <div className="blog-entry">
                <a
                  href="blog-single.html"
                  className="block-20 d-flex align-items-end"
                  style={{ backgroundImage: 'url("images/image_2.jpg")' }}
                >
                  <div className="meta-date text-center p-2">
                    <span className="day">26</span>
                    <span className="mos">June</span>
                    <span className="yr">2019</span>
                  </div>
                </a>
                <div className="text bg-white p-4">
                  <h3 className="heading">
                    <a href="/home">
                      Finance And Legal Working Streams Occur Throughout
                    </a>
                  </h3>
                  <p>
                    Far far away, behind the word mountains, far from the
                    countries Vokalia and Consonantia, there live the blind
                    texts.
                  </p>
                  <div className="d-flex align-items-center mt-4">
                    <p className="mb-0">
                      <a href="/home" className="btn btn-primary">
                        Read More
                        <span className="ion-ios-arrow-round-forward" />
                      </a>
                    </p>
                    <p className="ml-auto mb-0">
                      <a href="/home" className="mr-2">
                        Admin
                      </a>
                      <a href="/home" className="meta-chat">
                        <span className="icon-chat" /> 3
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 ftco-animate">
              <div className="blog-entry">
                <a
                  href="blog-single.html"
                  className="block-20 d-flex align-items-end"
                  style={{ backgroundImage: 'url("images/image_3.jpg")' }}
                >
                  <div className="meta-date text-center p-2">
                    <span className="day">26</span>
                    <span className="mos">June</span>
                    <span className="yr">2019</span>
                  </div>
                </a>
                <div className="text bg-white p-4">
                  <h3 className="heading">
                    <a href="/home">
                      Finance And Legal Working Streams Occur Throughout
                    </a>
                  </h3>
                  <p>
                    Far far away, behind the word mountains, far from the
                    countries Vokalia and Consonantia, there live the blind
                    texts.
                  </p>
                  <div className="d-flex align-items-center mt-4">
                    <p className="mb-0">
                      <a href="/home" className="btn btn-primary">
                        Read More
                        <span className="ion-ios-arrow-round-forward" />
                      </a>
                    </p>
                    <p className="ml-auto mb-0">
                      <a href="/home" className="mr-2">
                        Admin
                      </a>
                      <a href="/home" className="meta-chat">
                        <span className="icon-chat" /> 3
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
        <section className="ftco-section testimony-section">
          <div className="container">
            <div className="row justify-content-center mb-5">
              <div className="col-md-8 text-center heading-section ftco-animate">
                <h2 className="mb-4">Our Clients </h2>
              </div>
            </div>
            <div className="row ftco-animate justify-content-center">
              <div className="col-md-12">
                <div className="carousel-testimony owl-carousel">
                  <div className="item">
                    <div
                      className="testimony-wrap d-flex"
                      style={{ height: 200 }}
                    >
                      <img src="images/cleartax.png" alt="img"></img>
                    </div>
                  </div>
                  <div className="item">
                    <div
                      className="testimony-wrap d-flex"
                      style={{ height: 200 }}
                    >
                      <img src="images/sesamStreet.jpg" alt="img"></img>
                    </div>
                  </div>
                  <div className="item">
                    <div
                      className="testimony-wrap d-flex"
                      style={{ height: 200 }}
                    >
                      <img src="images/Phonix.png" alt="img"></img>
                    </div>
                  </div>
                  <div className="item">
                    <div
                      className="testimony-wrap d-flex"
                      style={{ height: 200 }}
                    >
                      <img src="images/Societe.png" alt="img"></img>
                    </div>
                  </div>
                  <div className="item">
                    <div
                      className="testimony-wrap d-flex"
                      style={{ height: 200 }}
                    >
                      <img src="images/SAP_logo.png" alt="img"></img>
                    </div>
                  </div>
                  <div className="item">
                    <div
                      className="testimony-wrap d-flex"
                      style={{ height: 200 }}
                    >
                      <img src="images/Marks.png" alt="img"></img>
                    </div>
                  </div>
                  <div className="item">
                    <div
                      className="testimony-wrap d-flex"
                      style={{ height: 200 }}
                    >
                      <img src="images/plains.png" alt="img"></img>
                    </div>
                  </div>
                  <div className="item">
                    <div
                      className="testimony-wrap d-flex"
                      style={{ height: 200 }}
                    >
                      <img src="images/Standard.png" alt="img"></img>
                    </div>
                  </div>
                  <div className="item">
                    <div
                      className="testimony-wrap d-flex"
                      style={{ height: 200 }}
                    >
                      <img src="images/commonFloor.png" alt="img"></img>
                    </div>
                  </div>
                  <div className="item">
                    <div
                      className="testimony-wrap d-flex"
                      style={{ height: 200 }}
                    >
                      <img src="images/Sansung.png" alt="img"></img>
                    </div>
                  </div>
                  <div className="item">
                    <div
                      className="testimony-wrap d-flex"
                      style={{ height: 200 }}
                    >
                      <img src="images/siemens.jpg" alt="img"></img>
                    </div>
                  </div>
                  <div className="item">
                    <div
                      className="testimony-wrap d-flex"
                      style={{ height: 200 }}
                    >
                      <img src="images/jda.webp" alt="img"></img>
                    </div>
                  </div>
                  <div className="item">
                    <div
                      className="testimony-wrap d-flex"
                      style={{ height: 200 }}
                    >
                      <img src="images/manhattan.png" alt="img"></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <ChatBot steps={steps} /> */}

      </>
    );
  };
}
export default withRouter(Home);
