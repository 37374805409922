import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import db from "../../hoc/Firebase";
import './Blogs.scss'

function BlogPage(props) {


  const [recent, Setrecent] = useState([]);
  const [Blog, SetBlog] = useState([]);
  const [recommend, Setrecommend] = useState([]);

  const getBlog = (id) => {
    //console.log("id from getblog", id)
    db.collection("Blogs")
      .doc(id)
      .onSnapshot(
        (doc) => {
          //console.log("doc", doc.data());
          // console.log("doc", doc.id);
          if (doc.exists) {
            let singleData = doc.data();
            singleData.id = doc.id;
            SetBlog(singleData);
          }
        },
        (error) => {
          console.log(error);
        }
      )
  }

  const getrecommended = () => {
    db.collection("Blogs")
      .onSnapshot(
        (querySnapshot) => {
          let blogsdata = [];
          querySnapshot.forEach((change) => {
            if (change.exists) {
              let blog = change.data();
              blog.id = change.id;
              blogsdata.push(blog);
            }
          });
          Setrecommend(blogsdata);
        },
        (error) => {
          console.log(error);
        }
      )
  }

  const getrecentBlog = () => {
    db.collection("Blogs")
      .onSnapshot(
        (querySnapshot) => {
          let blogsdata = [];
          querySnapshot.forEach((change) => {
            if (change.exists) {
              let blog = change.data();
              blog.id = change.id;
              blogsdata.push(blog);
            }
          });
          Setrecent(blogsdata);
        },
        (error) => {
          console.log(error);
        }
      )
  }
  useEffect(() => {
    getBlog(props.match.params.id)
    getrecommended();
  }, [props.match]);


  useEffect(() => {
    var jsElm = document.createElement("script");
    jsElm.src = "js/main.js";
    document.body.appendChild(jsElm);


    getrecentBlog();
  }, []);


  return (
    <div id="singleblog">
      <div className='blogpage-cover'>
        <img src="images/blogcover1.png" alt='quickserve it' className='blogpage-coverimage' />
      </div>
      <div className='blogbody-container'>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="/home">Home</a></li>
            <li className="breadcrumb-item"><a href="/blogs">Blogs</a></li>
            <li className="breadcrumb-item active" aria-current="page">{Blog.name}</li>
          </ol>
        </nav>
        <div className='row'>
          <div className='blogpage-body col-lg-8 col-md-12 col-sm-12  ftco-animate'>
            <div>
              <h5 className='blog-category'>{Blog.category}</h5>
              <div className='blogpage-title  ftco-animate'>
                <h3>{Blog.name}</h3>
              </div>
              <article className='blogpage-description blog-mt-2'>
                <p className='blogpage-para  ftco-animate'>{
                  Blog.description
                }
                </p>
                <img className='blogpage-desc-img  ftco-animate' alt='blog page' src={Blog.url} />
                <div className='blogpage-info'>
                  <p className='blogpage-date'> Created On : {
                    Blog.createdOn
                  }</p>
                </div>
                <div className='sub-heading blog-mt-2  ftco-animate'>
                  <h3>{Blog.subTopic}</h3>
                  <p>{Blog.subtopicdesc}</p>
                </div>
                <div className='blogpage-points blog-mt-2'>
                  <blockquote className='blogpage-remark  ftco-animate'>{Blog.remark}</blockquote>
                  <hr></hr>
                  <div>
                    <h3 className='blogpage-list-heading blog-mt-2  ftco-animate'>{Blog.listHeading}</h3>
                    <div className='blogpage-list-body mt-3  ftco-animate'>
                      <p className='blog-mt-2'>{Blog.listDesc}</p>
                      <ul>
                        <li className='blog-list'>{Blog.list}</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <p className='blog-footer'>{Blog.blogFooter}</p>
              </article>
            </div>
          </div>
          <div className='blogpage-recommend col-lg-4 col-md-12 col-sm-12'>
            <h5>Recommended Blogs</h5>
            <div className='blogpage-list-container'>
              {
                recommend.map((item, index) => {
                  return (
                    <div className='b-l' key={index} onClick={
                      (e) => {
                        e.preventDefault();
                        window.location.pathname = "/blogpage/" + item.id;
                        getBlog(item.id);
                      }} >
                      <div className='b-l-i'>
                        <img src={item.url} alt='blogs list' />
                      </div>
                      <div className='b-l-b'>
                        <h5 className='b-l-b-t'>{item.name}</h5>
                      </div>
                    </div>
                  );
                })
              }
            </div>
          </div>
        </div>
      </div>
      <div className='latest-blog'>
        <div className='container-flex'>
          <h3 className='l-b-h'>Check Out Our Latest Blogs</h3>
          <div id="blogpage-lg-carousel" class="carousel slide" data-ride="carousel">
            <ol class="carousel-indicators">
              <li data-target="#blogpage-lg-carousel" className="active" data-slide-to="0"></li>
              <li data-target="#blogpage-lg-carousel" data-slide-to="1"></li>
            </ol>
            <div class="carousel-inner">
              <div class="carousel-item active" data-interval="10000">
                <div className="row">
                  {
                    recent.map((item, index) => {
                      let trimdesc = item.description;
                      trimdesc = trimdesc.slice(0, 80);
                      let trimtime = item.createdOn;
                      trimtime = trimtime.slice(0, 15);
                      if (index < 3) {
                        return (
                          <div key={index} className="col-lg-3 col-md-3 card carousel-card" onClick={
                            (e) => {
                              e.preventDefault();
                              window.location.pathname = "/blogpage/" + item.id;
                              getBlog(item.id);
                            }
                          }>
                            <main className="readmore">
                              <img className='card-img-top' src={item.url} />
                              <div className='blog-middle'>
                                <h5 className='blog-text'>Read More..</h5>
                              </div>
                            </main>

                            <div className='card-body'>
                              <h3 className='card-title'>{item.name}</h3>
                              <p className='card-text'>{trimdesc} ...Read more
                              </p>
                            </div>
                            <div className="card-footer">
                              <small className="text-muted">Added On  --{trimtime}</small>
                            </div>
                          </div>
                        );
                      }
                    })
                  }
                </div>
              </div>
              <div class="carousel-item" data-interval="10000">
                <div className="row">
                  {
                    recent.map((item, index) => {
                      let trimdesc = item.description;
                      trimdesc = trimdesc.slice(0, 80);
                      let trimtime = item.createdOn;
                      trimtime = trimtime.slice(0, 15);
                      if (index >= 3 && index < 6) {
                        return (
                          <div key={index} className="col-lg-3 col-md-3 card carousel-card" onClick={
                            (e) => {
                              e.preventDefault();
                              window.location.pathname = "/blogpage/" + item.id;
                              getBlog(item.id);
                            }
                          }>
                            <main className="readmore">
                              <img className='card-img-top' src={item.url} />
                              <div className='blog-middle'>
                                <h5 className='blog-text'>Read More..</h5>
                              </div>
                            </main>
                            <div className='card-body'>
                              <h3 className='card-title'>{item.name}</h3>
                              <p className='card-text'>{trimdesc}...Read more
                              </p>
                            </div>
                            <div className="card-footer">
                              <small className="text-muted">Added On  --{trimtime}</small>
                            </div>
                          </div>
                        );
                      }
                    })
                  }
                </div>
              </div>
            </div>
            <a class="carousel-control-prev" href="#blogpage-lg-carousel" role="button" data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#blogpage-lg-carousel" role="button" data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>
          </div>


          <div id="blogpage-sm-carousel" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner">
              {
                recent.map((item, index) => {
                  let trimdesc = item.description;
                  trimdesc = trimdesc.slice(0, 80);
                  let trimtime = item.createdOn;
                  trimtime = trimtime.slice(0, 15);
                  if (index < 6) {
                    return (
                      <div key={index} className={(index === 0) ? "card carousel-item active carousel-card mx-4" : "card carousel-item carousel-card"} data-interval="5000" onClick={
                        (e) => {
                          e.preventDefault();
                          window.location.pathname = "/blogpage/" + item.id;
                          getBlog(item.id);
                        }
                      }>
                        <main className="readmore">
                          <img className='card-img-top' src={item.url} />
                          <div className='blog-middle'>
                            <h5 className='blog-text'>Read More..</h5>
                          </div>
                        </main>
                        <div className='card-body'>
                          <h3 className='card-title'>{item.name}</h3>
                          <p className='card-text'>{trimdesc}
                          </p>
                        </div>
                        <div className="card-footer">
                          <small className="text-muted">Added On  --{trimtime}</small>
                        </div>
                      </div>
                    );
                  }
                })
              }
            </div>
            <a class="carousel-control-prev" href="#blogpage-sm-carousel" role="button" data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#blogpage-sm-carousel" role="button" data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(BlogPage);