import React, { useEffect, useState, useLayoutEffect } from "react";
import { useWindowScroll } from 'react-use'
import { isMobile } from "react-device-detect";
import reactRouterDom from "react-router-dom";
import './Career.css';

const perks = [
  {
    img: "images/work-from-home.png",
    title: "Remote jobs",
    desc: "There is full-time remote employment accessible. You can accomplish your job from the convenience of your own home. We have faith in our team and trust that you will all work hard to improve the company's reputation.",
  },
  {
    img: "images/salary.png",
    title: "Competitive Salary",
    desc: "You deserve entitlement and recognition for your achievements. We pay you well for your efforts. You could be eligible for additional bonuses and incentives based on your performance and productivity.",
  },
  {
    img: "images/working-hours.png",
    title: "Flexible hours",
    desc: "Task completion is important. When you do so, it is entirely at your discretion. We don't believe in fixed working hours. Instead, we have incorporated flexibility into our work cultures. You have complete freedom to work whenever and from anywhere you wish.",
  },
  {
    img: "images/vacations.png",
    title: "Paid holiday",
    desc: "Yes, we recognize that you have other essential responsibilities and that situations may arise! We believe in paid time off, and don't panic, you won't have to argue your valid reasons over and over again. We've got your back.",
  },
  {
    img: "images/goal.png",
    title: "Personal growth",
    desc: "In our sector, learning new skills must never be postponed. You should work on improving your talents every day and every moment. Only then will you be capable of competing in the future with other companies. QSITS encourages each employee to develop their skills with the best available coaching. We help you prepare for the future.",
  },
]
function Career(props) {

  const [scrollposition, Setscrollposition] = useState(0);
  const { x, y } = useWindowScroll();

  useEffect(() => {
    const progressbar=document.getElementById("progress-bar");
    progressbar.style.width="0%";
    if (y >= 870 && y <= 1200) {
      Setscrollposition(25);
      progressbar.style.width="20%";
    }
    else if(y >= 1245 && y <= 1700){
      progressbar.style.width="40%";
    }
    else if(y >= 1755 && y <= 2200){
      progressbar.style.width="60%";
    }
    else if(y >= 2255 && y <= 2555){
      progressbar.style.width="80%";
    }
    else if(y >= 2560){
      progressbar.style.width="100%";
    }


  }, [y]);


  useEffect(() => {
    var jsElm = document.createElement("script");
    jsElm.src = "js/main.js";
    document.body.appendChild(jsElm);
  }, []);

  return (
    <>
      <section
        className="hero-wrap hero-wrap-2"
        style={{ backgroundImage: 'url("images/career.jpg")' }}
      >
        <div className="overlay" />
        <div className="container">
          <div className="row no-gutters slider-text align-items-center justify-content-center">
            <div className="col-md-9 ftco-animate text-center">
              <h1 className="mb-2 bread">Career</h1>
              <p className="breadcrumbs">
                <span className="mr-2">
                  <a href="index.html">
                    Home <i className="ion-ios-arrow-forward" />
                  </a>
                </span>
                <span>Career</span>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="ftco-section ftco-animate">
        <div className="container">
          <div className="row justify-content-center  ">
            <div className="col-md-12 col-lg-12 col-sm-12">
              <section className="career-intro">
                <header className="ftco-animate">
                  <h2>Working at QuickServe IT solutions</h2>
                  <h4> - transform the industry together </h4>
                </header>
                <p className="ftco-animate">Working at QSITS is a whole new experience. You're empowered to
                  create, you're given the freedom to explore, and you're given the
                  opportunity to innovate. You'll have the support of an entire
                  network of people looking out for your best interests and
                  inspiring you to try new things. You'll have an opportunity
                  to grow in one of the most diverse work environments in town.
                  It's a place where failure is not an option, only success
                  no matter what your age or background.</p>
                <p className="ftco-animate">
                  We're looking for people who want to take their careers in the
                  field of virtualization and achieve success. Come be a part of
                  this community that's celebrating passion in the profession.
                  Apply now for a job at QSITS!
                </p>
              </section>
            </div>
          </div>
        </div>
      </section>
      <section id="career-perks">
        <div className="text-center row">
          <main className="col-lg-6 col-md-6 career-perks-leftCol ftco-animate">
            <header className="career-perks-heading">
              <h2>How to apply at QSITS?</h2>
              <p>You can apply straight from the website if there are any open
                positions that have been posted recently. If not, send us your
                resume and we'll examine what positions are available and open
                for you. Those who are willing to push themselves beyond their
                limits and achieve the unachievable in the most ingenious
                methods will be recognized in our company.</p>
              <div className="mt-2 pt-2 ">
                <h3>The Perks of Working for QuickServe</h3>
              </div>
              <div class="progress" style={{height:"2px"}}>
                <div class="progress-bar" id="progress-bar" role="progressbar"  aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </header>

          </main>
          <div className="col-lg-6 col-md-6">
            <div className="perks-container" >
              {
                perks.map((perk, index) => {
                  return (
                    <div className="perks-body ftco-animate">
                      <img className="perks-img" src={perk.img} />
                      <header>
                        <h2 className="perks-title">{perk.title}</h2>
                      </header>
                      <main>
                        <p className="perks-desc">{perk.desc}</p>
                      </main>
                    </div>
                  );
                })
              }
            </div>
          </div>
        </div>
      </section>
      <section>
        <main className="career-footer">
          <header className="ftco-animate c-f-h">
            <h2>Our Work Values</h2>
            <span></span>
          </header>
          <div className="ftco-animate">
            <div className="container footer-desc">
              <p>QSITS is a leading provider of the latest software services, a hub for innovation,
                and a driver of positive change. We are passionate about creating better lives for
                developers, inventing new opportunities for business growth, and collaborating with
                the brightest minds to create a software company that is the best in the market.
                We provide our employees with continuous upskill opportunities to make sure they
                are able to stay current with the latest industry trends and technology.</p>
              <p>We have the strongest faith in our employees. We value them and recognize
                that they give their all to the company's success. At the same time, QSITS
                also values the importance of a strong relationship with its stakeholders.
                We take pride in being a trusted partner that is dependable and committed to
                creating a sustainable future for the people who deserve it most.</p>
              <p>
                QSITS has been providing reliable software services to fulfill customer needs
                for over 2 decades. Our extensive engagement across employees, along with our
                dedication and enthusiasm for excellence, results in the greatest possible customer
                service experience. We value, and invest in, relationships with their customers,
                partners, and employees; which is why we take an intimate approach to understand
                their needs and values before taking any decision.
              </p>
            </div>
          </div>
        </main>
      </section>
    </>
  );
}
export default Career;
