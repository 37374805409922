import React, { useEffect, useState } from 'react';
import db from "../../hoc/Firebase";
//import './Blogs.scss';

function Blogs(props) {
    
    const handlesingleblog = (id) => {
        console.log(id, "handle single blog clicked");
        console.log("e::", id);
        window.location.pathname = "/blogpage/" + id;
    }

    useEffect(() => {
        var jsElm = document.createElement("script");
        jsElm.src = "js/main.js";
        document.body.appendChild(jsElm);
    }, []);

    return (
        <div id='blogs' >
            <section
                className="hero-wrap hero-wrap-2"
                style={{ backgroundImage: 'url("images/blogcover1.png")' }}
            >
                <div className="overlay" />
                <div className="container">
                    <div className="row no-gutters slider-text align-items-center justify-content-center">
                        <div className="col-md-9 ftco-animate text-center">
                            <h1 className="mb-2 bread">Blogs</h1>
                            <p className="breadcrumbs">
                                <span className="mr-2">
                                    <a href="/">
                                        Home <i className="ion-ios-arrow-forward" />
                                    </a>
                                </span>
                                <span>Blogs</span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="blog-landing">
                <section className='landing fadeInUp ftco-animated'>
                    <div className='mb-2'>
                        <div className='row justify-content-center'>
                            {
                                props.blogs.map((blog, index) => {
                                    let time = blog.createdOn;
                                    let blogdesc = blog.description;
                                    return (

                                        <div className='blog-container mx-1 col-lg-3 col-md-4 col-sm-12  fadeInUp mb-5' key={index} onClick={(e) => {
                                            e.preventDefault();
                                            handlesingleblog(blog.id);
                                        }} style={{ cursor: "pointer" }}
                                        >
                                            <main className='blogs-card'>
                                                <img src={blog.url} className="landing-img" alt='QuickServe IT Solution Blogs'></img>
                                                <div className='blog-middle'>
                                                    <h5 className='blog-text'>Read More..</h5>
                                                </div>
                                            </main>
                                            <div className='blog-body'>
                                                <p className='landing-createdon'>{time.slice(3, 15)}</p>
                                                <h3 className='landing-title'>{blog.name}</h3>
                                                <p className='landing-desc'>{blogdesc.slice(0, 130)}...</p>
                                                <h4 className='landing-category'>{blog.category}</h4>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </section>
            </section>
        </div>
    );
}

export default Blogs;
