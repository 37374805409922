import React, { useEffect, useState } from 'react'

function BlogsPagination({ postperpage, totalBlogs, paginate, currentpage}) {
    //const [pagecount,setpagecount]=useState([]);
    const pagenumber = [];
    console.log(currentpage, "Getting current page");
    //console.log(postperpage, totalBlogs, "Printint gfrom paginations");
    for (let i = 1; i <= Math.ceil(totalBlogs / postperpage); i++) {
        pagenumber.push(i);
    }


    return (
        <section className='text-center justify-content-center blogs-pagination'  >
            <nav aria-label="Page navigation example ">
                <ul className="pagination justify-content-center">
                    {
                        pagenumber.map(number => {
                            return (
                                <div key={number}>
                                    <li className={(currentpage === number) ? "page-item active" : "page-item"} id='page-item'>
                                        <a className="page-link" id="active-number" style={{ cursor: "pointer" }} onClick={() => {
                                            paginate(number);
                                        }}>{number}</a>
                                    </li>

                                </div>
                            );
                        })
                    }
                </ul>
            </nav>
        </section>
    )
}

export default BlogsPagination