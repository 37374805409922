import React from 'react'
import { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import Blogs from './Blogs';
import db from "../../hoc/Firebase";
import BlogsPagination from './BlogsPagination';

function BlogController(props) {

    const [blogs, Setblogs] = useState([]);
    const [currentpage, setcurrentpage] = useState(1)
    const [blogperpage, Setblogperpage] = useState(3);

    const indexoflastpost = currentpage * blogperpage;
    const indexoffirestpost = indexoflastpost - blogperpage;
    const currentpost = blogs.slice(indexoffirestpost, indexoflastpost);
    const [blogscount,Setblogscount]=useState(0);


    const getBlogs = () => {
        db.collection("Blogs")
            .onSnapshot(
                (querySnapshot) => {
                    let blogsdata = [];
                    querySnapshot.forEach((change) => {
                        if (change.exists) {
                            let blog = change.data();
                            blog.id = change.id;
                            blogsdata.push(blog);
                        }
                    });
                    Setblogs(blogsdata);
                },
                (error) => {
                    console.log(error);
                }
            )
    }

    const paginate=(number)=>{
        setcurrentpage(number);
        window.scrollTo(0, 0);
    }


    useEffect(() => {
        var jsElm = document.createElement("script");
        jsElm.src = "js/main.js";
        document.body.appendChild(jsElm);

        getBlogs();
        //console.log("checking have blogs", haveblogs);
        console.log("blogcontroller called");
    }, []);

    return (
        <div>
            <Blogs blogs={currentpost}  />
            <BlogsPagination postperpage={blogperpage} currentpage={currentpage} totalBlogs={blogs.length} paginate={paginate} active={currentpage}/>
        </div>
    )
}

export default withRouter(BlogController);