import React from "react";
import Home from "./pages/Home";
import About from "./pages/About";
import Projects from "./pages/Projects";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
// import OurTeam from "./pages/Team";
//import Blogs from "./pages/Blogs/Blogs";
import Career from "./pages/Career/Career";
import BlogController from "./pages/Blogs/BlogController";
//import BlogPage from "./pages/Blogs/BlogPage";

const Routes = () => {
  return [
    {
      path: "/",
      component: <Home />,
    },
    {
      path: "/home",
      component: <Home />,
    },
    {
      path: "/about",
      component: <About />,
    },
    {
      path: "/projects",
      component: <Projects />,
    },
    {
      path: "/services",
      component: <Services />,
    },
    {
      path: "/contact",
      component: <Contact />,
    },
    // {
    //   path: "/team",
    //   component: <OurTeam />,
    // },
    {
      path: "/blogs",
      component: <BlogController />,
    },
    {
      path: "/career",
      component: <Career />,
    },
    // {
    //   path: "/blogpage/:id",
    //   component: <BlogPage />,
    // },
  ];
};

export default Routes;
