import React from "react";
import { isMobile } from "react-device-detect";
import { withRouter } from "react-router-dom";
function Footer(props) {
  const path = props.location.pathname;
  return (
    <footer
      className="ftco-footer ftco-bg-dark ftco-section"
      style={{ paddingTop: 30, paddingBottom: 1 }}
    >
      <div className="container">
        <div className="row mb-2">
          <div className="col-md-6 col-sm-12 col-lg-6">
            <div className="ftco-footer-widget mb-2">
              <h2 className="ftco-heading-2 mb-2">Contact Us</h2>
              <div className="block-23 mb-1">
              <ul>
                  <li>
                    <span className="icon icon-map-marker" />
                    <span className="text">{window.address}</span>
                  </li>

                  <li>
                    {/* <a href="/home"> */}
                    <span className="icon icon-envelope" />
                    <span className="text">{window.mail}</span>
                    {/* </a> */}
                  </li>
                  <li>
                    {/* <a href="/home"> */}
                    <span className="icon-phone2" />
                    <span className="text">{window.phone}</span>
                    {/* </a> */}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* <div
            className="col-md-6 col-sm-12  col-lg-3"
            style={{ paddingTop: isMobile ? "" : 35 }}
          >
            <div className="ftco-footer-widget mb-5">
              <div className="block-23 mb-3">
                <ul>
                  <li>
                    <span className="icon icon-map-marker" />
                    <span className="text">{window.address}</span>
                  </li>

                  <li>
                    <span className="icon icon-envelope" />
                    <span className="text">{window.mail}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
          <div className="col-md-6 col-sm-12  col-lg-6">
            <div className="ftco-footer-widget mb-2 ml-md-2">
              <h2 className="ftco-heading-2 mb-2">Important Links</h2>
              <ul className="list-unstyled">
                <li
                  style={{
                    color: path === "/home" ? "#1b9ce3" : "aliceblue",
                  }}
                >
                  <a href="/home">
                    <span className="ion-ios-arrow-round-forward mr-2" />
                    Home
                  </a>
                </li>
                <li
                  style={{
                    color: path === "/about" ? "#1b9ce3" : "aliceblue",
                  }}
                >
                  <a href="/about">
                    <span className="ion-ios-arrow-round-forward mr-2" />
                    About Us
                  </a>
                </li>
                <li
                  style={{
                    color: path === "/projects" ? "#1b9ce3" : "aliceblue",
                  }}
                >
                  <a href="/projects">
                    <span className="ion-ios-arrow-round-forward mr-2" />
                    Projects
                  </a>
                </li>
                <li
                  style={{
                    color: path === "/services" ? "#1b9ce3" : "aliceblue",
                  }}
                >
                  <a href="/services">
                    <span className="ion-ios-arrow-round-forward mr-2" />
                    Services
                  </a>
                </li>
                <li
                  style={{
                    color: path === "/contact" ? "#1b9ce3" : "aliceblue",
                  }}
                >
                  <a href="/contact">
                    <span className="ion-ios-arrow-round-forward mr-2" />
                    Contact Us
                  </a>
                </li>
                <li
                  style={{
                    color: path === "/team" ? "#1b9ce3" : "aliceblue",
                  }}
                >
                  <a href="/team">
                    <span className="ion-ios-arrow-round-forward mr-2" />
                    Our Team
                  </a>
                </li>
                <li
                  style={{
                    color: path === "/career" ? "#1b9ce3" : "aliceblue",
                  }}
                >
                  <a href="/career">
                    <span className="ion-ios-arrow-round-forward mr-2" />
                    Career
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-6 col-sm-12  col-lg-4">
            <div className="ftco-footer-widget mb-2 ml-md-2">
              {/* <h2 className="ftco-heading-2 mb-2">Links</h2>
              <ul>
                <li><a href="/career" target={"_blank"}><i class="icon-facebook"></i></a></li>
              </ul> */}
        </div>
        </div>
        </div>
       
      </div>
      <div className="row border-top">
          <div className="col-md-12 text-center">
            <p className="pt-3" style={{fontSize:20}}>
              {`Copyright ©${new Date().getFullYear()} All rights reserved |`}
              <span>{` ${window.companyName}`}</span>
            </p>
          </div>
        </div>
    </footer>
  );
}

export default withRouter(Footer);
