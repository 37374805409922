import React from "react";
import { isMobile } from "react-device-detect";
class Projects extends React.Component {
  componentDidMount() {
    var jsElm = document.createElement("script");
    jsElm.src = "js/main.js";
    document.body.appendChild(jsElm);
  }
  render = () => {
    return (
      <>
        <section
          className="hero-wrap hero-wrap-2"
          style={{ backgroundImage: 'url("images/projects1.jpg")' }}
        >
          <div className="overlay" />
          <div className="container">
            <div className="row no-gutters slider-text align-items-center justify-content-center">
              <div className="col-md-9 ftco-animate text-center">
                <h1 className="mb-2 bread">Projects</h1>
                <p className="breadcrumbs">
                  <span className="mr-2">
                    <a href="index.html">
                      Home <i className="ion-ios-arrow-forward" />
                    </a>
                  </span>
                  <span>Projects</span>
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="ftco-section">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div
                  className="project mb-4 img ftco-animate d-flex justify-content-center align-items-center"
                  style={{ backgroundImage: "url(images/books.png)" }}
                >
                  <div className={isMobile ? "overlayProject" : "overlay"} />

                  <a
                    href="/"
                    className={
                      isMobile
                        ? "btn-siteproject d-flex align-items-center justify-content-center"
                        : "btn-site d-flex align-items-center justify-content-center"
                    }
                  >
                    <span className="icon-subdirectory_arrow_right" />
                  </a>
                  <div className="text text-center p-4">
                    <h3
                      style={{
                        fontSize: 20,
                        textAlign: "initial",
                        color: "white",
                      }}
                    >
                      Book Distribution System
                    </h3>
                    <span style={{ fontSize: 12, textAlign: "initial" }}>
                      <p className="description">
                        <span>{`What does it do?`}</span>
                        <br />
                        <span>{`- Manages stock.`}</span>
                        <br />
                        <span>{`- Makes books ready to go, on prior request.`}</span>
                        <br />
                        <span>{`-Expense Detailing.`}</span>
                        <br />
                        <span>{`-Managing database of the partners around the market.`}</span>
                        &nbsp;
                      </p>
                    </span>
                  </div>
                  <span id="projectHeading">Book Distribution System</span>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className="project mb-4 img ftco-animate d-flex justify-content-center align-items-center"
                  style={{ backgroundImage: "url(images/tracking.png)" }}
                >
                  <div className={isMobile ? "overlayProject" : "overlay"} />

                  <a
                    href="/"
                    className={
                      isMobile
                        ? "btn-siteproject d-flex align-items-center justify-content-center"
                        : "btn-site d-flex align-items-center justify-content-center"
                    }
                  >
                    <span className="icon-subdirectory_arrow_right" />
                  </a>
                  <div className="text text-center p-4">
                    <h3
                      style={{
                        fontSize: 20,
                        textAlign: "initial",
                        color: "white",
                      }}
                    >
                      Tracking application
                    </h3>
                    <span style={{ fontSize: 12, textAlign: "initial" }}>
                      <p className="description">
                        {`We have made a tracking application to track the associates end to end. Associates have to just start the application and application will make route of the same.So that the information provided by the associates can be cross checked and the owner can evaluate money for accountability.`}
                      </p>
                    </span>
                  </div>
                  <span id="projectHeading">Tracking application</span>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className="project mb-4 img ftco-animate d-flex justify-content-center align-items-center"
                  style={{ backgroundImage: "url(images/Tractor.png)" }}
                >
                  <div className={isMobile ? "overlayProject" : "overlay"} />

                  <a
                    href="/"
                    className={
                      isMobile
                        ? "btn-siteproject d-flex align-items-center justify-content-center"
                        : "btn-site d-flex align-items-center justify-content-center"
                    }
                  >
                    <span className="icon-subdirectory_arrow_right" />
                  </a>
                  <div className="text text-center p-4">
                    <h3
                      style={{
                        fontSize: 20,
                        textAlign: "initial",
                        color: "white",
                      }}
                    >
                      Tractor App
                    </h3>
                    <span style={{ fontSize: 12, textAlign: "initial" }}>
                      <p className="description">
                        {`We have provided the basis of categorised clients with multiple stores and user(admin/ operator). Inventory management has been done with no hustle at all. And we are also showing reports at various level so that admin can plan his/her day ahead.`}{" "}
                      </p>
                    </span>
                  </div>
                  <span id="projectHeading">Tractor App</span>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className="project mb-4 img ftco-animate d-flex justify-content-center align-items-center"
                  style={{ backgroundImage: "url(images/loan.webp)" }}
                >
                  <div className={isMobile ? "overlayProject" : "overlay"} />

                  <a
                    href="/"
                    className={
                      isMobile
                        ? "btn-siteproject d-flex align-items-center justify-content-center"
                        : "btn-site d-flex align-items-center justify-content-center"
                    }
                  >
                    <span className="icon-subdirectory_arrow_right" />
                  </a>
                  <div className="text text-center p-4">
                    <h3
                      style={{
                        fontSize: 20,
                        textAlign: "initial",
                        color: "white",
                      }}
                    >
                      Loan by Jewellery Shop
                    </h3>
                    <span style={{ fontSize: 12, textAlign: "initial" }}>
                      <p className="description">
                        We have provided to client on taking the details and
                        performing calculations <br />
                        (Simple and Compound Interests) implicable to their
                        clients, with ease and make the things work paperless.
                      </p>
                    </span>
                  </div>
                  <span id="projectHeading">Loan by Jewellery Shop</span>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className="project mb-4 img ftco-animate d-flex justify-content-center align-items-center"
                  style={{
                    backgroundImage:
                      "url(https://www.techjockey.com/blog/wp-content/uploads/2019/02/Inventory-Management-Software_feature.png)",
                  }}
                >
                  <div className={isMobile ? "overlayProject" : "overlay"} />

                  <a
                    href="/"
                    className={
                      isMobile
                        ? "btn-siteproject d-flex align-items-center justify-content-center"
                        : "btn-site d-flex align-items-center justify-content-center"
                    }
                  >
                    <span className="icon-subdirectory_arrow_right" />
                  </a>
                  <div className="text text-center p-4">
                    <h3
                      style={{
                        fontSize: 20,
                        textAlign: "initial",
                        color: "white",
                      }}
                    >
                      Stock Management
                    </h3>
                    <span style={{ fontSize: 12, textAlign: "initial" }}>
                      <p className="description">
                        This makes the organization's functions paperless, where
                        Engineers visited various organizations for water Add
                        data for all tests in treatment and Admin Panel Managed
                        Applications Making report month wise, stock wise and
                        recommended Water stock of essential chemicals
                        Remediation in Customer Location.
                      </p>
                    </span>
                  </div>
                  <span id="projectHeading">Stock Management</span>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className="project mb-4 img ftco-animate d-flex justify-content-center align-items-center"
                  style={{
                    backgroundImage:
                      "url(https://miro.medium.com/max/3672/1*TznLKd-Ig7PNv1fVL2zauA.jpeg)",
                  }}
                >
                  <div className={isMobile ? "overlayProject" : "overlay"} />

                  <a
                    href="/"
                    className={
                      isMobile
                        ? "btn-siteproject d-flex align-items-center justify-content-center"
                        : "btn-site d-flex align-items-center justify-content-center"
                    }
                  >
                    <span className="icon-subdirectory_arrow_right" />
                  </a>
                  <div className="text text-center p-4">
                    <h3
                      style={{
                        fontSize: 20,
                        textAlign: "initial",
                        color: "white",
                      }}
                    >
                      News App
                    </h3>
                    <span style={{ fontSize: 12, textAlign: "initial" }}>
                      <p className="description">
                        News aggregator application for various web browsers.It
                        compiles news feeds from a variety of online sources for
                        the user to customize and share with others.
                      </p>
                    </span>
                  </div>
                  <span id="projectHeading">News App</span>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className="project mb-4 img ftco-animate d-flex justify-content-center align-items-center"
                  style={{
                    backgroundImage:
                      "url(img/gallery/taour.jpg)",
                  }}
                >
                  <div className={isMobile ? "overlayProject" : "overlay"} />

                  <a
                    href="/"
                    className={
                      isMobile
                        ? "btn-siteproject d-flex align-items-center justify-content-center"
                        : "btn-site d-flex align-items-center justify-content-center"
                    }
                  >
                    <span className="icon-subdirectory_arrow_right" />
                  </a>
                  <div className="text text-center p-4">
                    <h3
                      style={{
                        fontSize: 20,
                        textAlign: "initial",
                        color: "white",
                      }}
                    >
                      Tour And Travels
                    </h3>
                    <span style={{ fontSize: 12, textAlign: "initial" }}>
                      <p className="description">
                        This application provides a vehicle for tourists to plan
                        more tours with less cost.
                      </p>
                    </span>
                  </div>
                  <span id="projectHeading">Tour And Travels</span>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className="project mb-4 img ftco-animate d-flex justify-content-center align-items-center"
                  style={{
                    backgroundImage:
                      "url(https://5.imimg.com/data5/TH/EA/MY-41724029/institute-management-system-500x500.png)",
                  }}
                >
                  <div className={isMobile ? "overlayProject" : "overlay"} />

                  <a
                    href="/"
                    className={
                      isMobile
                        ? "btn-siteproject d-flex align-items-center justify-content-center"
                        : "btn-site d-flex align-items-center justify-content-center"
                    }
                  >
                    <span className="icon-subdirectory_arrow_right" />
                  </a>
                  <div className="text text-center p-4">
                    <h3
                      style={{
                        fontSize: 20,
                        textAlign: "initial",
                        color: "white",
                      }}
                    >
                      Coaching Institute Management App
                    </h3>
                    <span style={{ fontSize: 12, textAlign: "initial" }}>
                      <p className="description">
                        It has two mobile app one is for the details of courses
                        and batches, and another one is a student and fees
                        management system where we can manage both student's
                        inquiry as well as there registrations. Here we can
                        create multiple batches, courses and also able to create
                        study material kit.
                      </p>
                    </span>
                  </div>
                  <span id="projectHeading">
                    Coaching Institute Management App
                  </span>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className="project mb-4 img ftco-animate d-flex justify-content-center align-items-center"
                  style={{
                    backgroundImage:
                      "url(img/gallery/crm.avif)",
                  }}
                >
                  <div className={isMobile ? "overlayProject" : "overlay"} />

                  <a
                    href="/"
                    className={
                      isMobile
                        ? "btn-siteproject d-flex align-items-center justify-content-center"
                        : "btn-site d-flex align-items-center justify-content-center"
                    }
                  >
                    <span className="icon-subdirectory_arrow_right" />
                  </a>
                  <div className="text text-center p-4">
                    <h3
                      style={{
                        fontSize: 20,
                        textAlign: "initial",
                        color: "white",
                      }}
                    >
                      CRM
                    </h3>
                    <span style={{ fontSize: 12, textAlign: "initial" }}>
                      <p className="description">
                        It is a CRM application where multiple users roles are
                        managing by client. Where customer's need like enquiry,
                        order, order delivery and order tracking are managed by
                        admin. Admin can also manage and monitor new leads
                        followup. There are many more functionality like admin
                        can also able to broadcast customers due by the sms
                        notifier module.
                      </p>
                    </span>
                  </div>
                  <span id="projectHeading">CRM</span>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className="project mb-4 img ftco-animate d-flex justify-content-center align-items-center"
                  style={{
                    backgroundImage:
                      "url(img/gallery/coaching.avif)",
                  }}
                >
                  <div className={isMobile ? "overlayProject" : "overlay"} />

                  <a
                    href="/"
                    className={
                      isMobile
                        ? "btn-siteproject d-flex align-items-center justify-content-center"
                        : "btn-site d-flex align-items-center justify-content-center"
                    }
                  >
                    <span className="icon-subdirectory_arrow_right" />
                  </a>
                  <div className="text text-center p-4">
                    <h3
                      style={{
                        fontSize: 20,
                        textAlign: "initial",
                        color: "white",
                      }}
                    >
                      Coaching Institute
                    </h3>
                    <span style={{ fontSize: 12, textAlign: "initial" }}>
                      <p className="description">
                        It is a student management system where we can manage
                        both student enquiry as well as there registration also.
                        There is a good functionality where we can convert
                        enquiry into registration by updating fewer details.
                        Here we can create multiple batches, courses and we are
                        also able to create study material kit.
                      </p>
                    </span>
                  </div>
                  <span id="projectHeading">Coaching Institute</span>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className="project mb-4 img ftco-animate d-flex justify-content-center align-items-center"
                  style={{
                    backgroundImage:
                      "url(https://5.imimg.com/data5/XR/HK/XD/SELLER-2098150/retail-pos-system-software-software-500x500.png)",
                  }}
                >
                  <div className={isMobile ? "overlayProject" : "overlay"} />

                  <a
                    href="/"
                    className={
                      isMobile
                        ? "btn-siteproject d-flex align-items-center justify-content-center"
                        : "btn-site d-flex align-items-center justify-content-center"
                    }
                  >
                    <span className="icon-subdirectory_arrow_right" />
                  </a>
                  <div className="text text-center p-4">
                    <h3
                      style={{
                        fontSize: 20,
                        textAlign: "initial",
                        color: "white",
                      }}
                    >
                      POS
                    </h3>
                    <span style={{ fontSize: 12, textAlign: "initial" }}>
                      <p className="description">
                        This is a point of sale system where multiple branches
                        are managed by admin. There admin user can able to see
                        all branches sale and purchase date wise as well as he
                        is able to generate report within any time period.
                      </p>
                    </span>
                  </div>
                  <span id="projectHeading">POS</span>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className="project mb-4 img ftco-animate d-flex justify-content-center align-items-center"
                  style={{
                    backgroundImage:
                      "url(https://www.subscriptionflow.com/wp-content/uploads/2020/01/gym_management-subscription-software.jpg)",
                  }}
                >
                  <div className={isMobile ? "overlayProject" : "overlay"} />

                  <a
                    href="/"
                    className={
                      isMobile
                        ? "btn-siteproject d-flex align-items-center justify-content-center"
                        : "btn-site d-flex align-items-center justify-content-center"
                    }
                  >
                    <span className="icon-subdirectory_arrow_right" />
                  </a>
                  <div className="text text-center p-4">
                    <h3
                      style={{
                        fontSize: 20,
                        textAlign: "initial",
                        color: "white",
                      }}
                    >
                      Gym management
                    </h3>
                    <span style={{ fontSize: 12, textAlign: "initial" }}>
                      <p className="description">
                        Gym management system is an easy-to-use gym and health
                        club membership management system. It helps you keep
                        records of your members and their memberships, and
                        allows easy communication between you and your members.
                        Gym Management system is also feature-packed, helping
                        you in the management and growth of your club.
                      </p>
                    </span>
                  </div>
                  <span id="projectHeading">Gym management</span>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className="project mb-4 img ftco-animate d-flex justify-content-center align-items-center"
                  style={{
                    backgroundImage:
                      "url(https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQSc6qda_tQIOfKTVQMe6rPN6uDYB7XbxE177B7rLW1MxOS4FyR&usqp=CAU)",
                  }}
                >
                  <div className={isMobile ? "overlayProject" : "overlay"} />

                  <a
                    href="/"
                    className={
                      isMobile
                        ? "btn-siteproject d-flex align-items-center justify-content-center"
                        : "btn-site d-flex align-items-center justify-content-center"
                    }
                  >
                    <span className="icon-subdirectory_arrow_right" />
                  </a>
                  <div className="text text-center p-4">
                    <h3
                      style={{
                        fontSize: 20,
                        textAlign: "initial",
                        color: "white",
                      }}
                    >
                      Inventory System
                    </h3>
                    <span style={{ fontSize: 12, textAlign: "initial" }}>
                      <p className="description">
                        It manage the sales and foods orders table system.
                        manage to foods inventory for sale and purchase. manage
                        to billing system
                      </p>
                    </span>
                  </div>
                  <span id="projectHeading">Inventory System</span>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className="project mb-4 img ftco-animate d-flex justify-content-center align-items-center"
                  style={{
                    backgroundImage:
                      "url(https://www.sketchappsources.com/resources/source-image/elegantmind-e-commerce-ui-kit-josetcastaneda.png)",
                  }}
                >
                  <div className={isMobile ? "overlayProject" : "overlay"} />

                  <a
                    href="/"
                    className={
                      isMobile
                        ? "btn-siteproject d-flex align-items-center justify-content-center"
                        : "btn-site d-flex align-items-center justify-content-center"
                    }
                  >
                    <span className="icon-subdirectory_arrow_right" />
                  </a>
                  <div className="text text-center p-4">
                    <h3
                      style={{
                        fontSize: 20,
                        textAlign: "initial",
                        color: "white",
                      }}
                    >
                      E-Commerce
                    </h3>
                    <span style={{ fontSize: 12, textAlign: "initial" }}>
                      <p className="description">
                        It is an online e-commerce / shopping platform to
                        provide all good products.
                      </p>
                    </span>
                  </div>
                  <span id="projectHeading">E-Commerce</span>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className="project mb-4 img ftco-animate d-flex justify-content-center align-items-center"
                  style={{
                    backgroundImage:
                      "url(img/gallery/transport.avif)",
                  }}
                >
                  <div className={isMobile ? "overlayProject" : "overlay"} />

                  <a
                    href="/"
                    className={
                      isMobile
                        ? "btn-siteproject d-flex align-items-center justify-content-center"
                        : "btn-site d-flex align-items-center justify-content-center"
                    }
                  >
                    <span className="icon-subdirectory_arrow_right" />
                  </a>
                  <div className="text text-center p-4">
                    <h3
                      style={{
                        fontSize: 20,
                        textAlign: "initial",
                        color: "white",
                      }}
                    >
                      Transport Management
                    </h3>
                    <span style={{ fontSize: 12, textAlign: "initial" }}>
                      <p className="description">
                        A transportation management system (TMS) is a logistics
                        platform that uses technology to help businesses plan,
                        execute, and optimize the physical movement of goods,
                        both incoming and outgoing, and making sure the shipment
                        is compliant, proper documentation is available. This
                        kind of system is often part of a larger supply chain
                        management (SCM) system.
                      </p>
                    </span>
                  </div>
                  <span id="projectHeading">Transport Management</span>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  };
}
export default Projects;
